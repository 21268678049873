.headerSection {
  background: url(../../assets/header.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 800px;

  header {
    a {
      font-weight: 600;
      font-size: 1.2rem;
      color: black !important;
    }
    .logo {
      width: 7rem;
      height: 7rem;
    }

    .content {
      width: 50%;
      margin-top: 4rem;
      padding: 3rem 4rem 0 4rem;
      h2 {
        font-weight: 700 !important;
        font-size: 2.2rem;
        margin-bottom: 3rem;

        @media screen and (max-width: 600px) {
          font-size: 1.7rem;
        }
      }

      p {
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1.6rem;
        margin-bottom: 2rem;
      }

      @media screen and (max-width: 1100px) {
        width: 80%;
        margin-inline: auto;
        background-color: #ffffff82;
        padding: 2rem;
        border-radius: 1rem;
        margin-top: 5.5rem;
      }

      @media screen and (max-width: 600px) {
        margin-top: 3.5rem;
        width: 90%;
      }
    }
  }

  @media screen and (max-width: 600px) {
    height: auto;
    padding-bottom: 3rem;
  }
}

.sloganSection {
  background-color: #030f90;
  padding-block: 5rem;
  h2 {
    font-weight: 600 !important;
  }
}

.missionSection {
  padding-block: 3rem;
  h2 {
    margin-bottom: 5rem;
    font-weight: 700 !important;
  }
  .missionItems {
    align-items: stretch;
    .missionItem {
      flex: 1;
      img {
        width: 6rem;
        height: 6rem;
        object-fit: contain;
      }
      p {
        font-size: 1rem;
      }

      @media screen and (max-width: 767px) {
        flex: 0 0 calc(50% - 1.5rem);
      }

      @media screen and (max-width: 600px) {
        flex: 0 0 100%;
      }
    }
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }
}

.objectiveSection {
  background: url(../../assets/objectives.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding-block: 5rem;
  justify-content: end;

  .content {
    width: 50%;
    padding-right: 4rem;
    padding-left: 1.5rem;
    h2 {
      font-weight: 700 !important;
      font-size: 2.4rem;

      @media screen and (max-width: 600px) {
        font-size: 1.7rem;
      }
    }
    ul {
      li {
        font-size: 1.1rem;
        margin-bottom: 0.5rem;

        &::marker {
          font-size: 2rem;
        }

        @media screen and (max-width: 600px) {
          font-size: 0.9rem;

          &::marker {
            font-size: 1.5rem;
          }
        }
      }
      @media screen and (max-width: 600px) {
        padding-left: 1.3rem;
      }
    }

    @media screen and (max-width: 1100px) {
      width: 80%;
      margin-inline: auto;
      background-color: #ffffffd9;
      padding: 2rem;
      border-radius: 1rem;
    }

    @media screen and (max-width: 600px) {
      width: 90%;
    }
  }
}

.historySection {
  padding-block: 5rem;
  gap: 5rem;

  h2 {
    font-weight: 700 !important;
    font-size: 2.4rem;
  }
  .content {
    flex: 1;
  }
  .imgWrap {
    flex: 1;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 991.99px) {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 3rem;
  }
}

.contactSection {
  background-color: #030f90;
  padding-block: 3rem;
  color: white;

  h2 {
    font-weight: 700 !important;
  }
  .info {
    img {
      width: 2rem;
      height: 2rem;
      object-fit: contain;
      margin-right: 1.5rem;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
      gap: 3rem;

      & > div {
        margin: auto;
      }
    }
  }
}
